<template>
	<div class="ClueReport">
		<w-navTab titleText="反映问题"></w-navTab>
		<div class="container">
			<div class="header-top">
				<div class="header-top-title">反映问题</div>
				<div class="header-top-info" @click="$router.push({ name: 'ClueReportList'})">反映记录</div>
			</div>
			<div class="head-form">
				<div class="head-form-item" @click="$router.push({ name: 'ClueType' })">
					<div class="item-left">反映类型</div>
					<div class="item-right">
						<div class="item-right-text">{{typeText}}</div>
						<div class="item-right-box"><van-icon style="margin-left: 10px;" name="play" color="#777B81" /></div>
					</div>
				</div>
				<div class="head-form-item" @click="show = true">
					<div class="item-left">案发时间</div>
					<div class="item-right">
						<div class="item-right-text">{{timeText}}</div>
						<div class="item-right-box"><van-icon style="margin-left: 10px;" name="play" color="#777B81" /></div>
					</div>
				</div>
				<div class="head-form-item" @click="$router.push({ name: 'ClueMap' })">
					<div class="item-left">案发位置</div>
					<div class="item-right">
						<div class="item-right-text">{{address}}</div>
						<div class="item-right-box">定位</div>
						<div class="item-right-box-picture">
							<img src="../../assets/img/icon54.png" alt="">
						</div>
					</div>
				</div>
				<div class="head-form-item">
					<div class="item-left">详细地址</div>
					<div class="item-right">
						<textarea v-model="detail_address" placeholder="请输入案发详细地址"></textarea>
					</div>
				</div>
				<div class="head-form-item" @click="$router.push({ name: 'ClueSelectPolice' })">
					<div class="item-left">公安局</div>
					<div class="item-right">
						<div class="item-right-text">{{policeText}}</div>
						<div class="item-right-box"><van-icon style="margin-left: 10px;" name="play" /></div>
					</div>
				</div>
			</div>
			<div class="item-footer">
				<div class="footer-select">
					<van-checkbox v-model="isSecrecy" shape="square">需要保密</van-checkbox>
				</div>
				<div class="footer-select">
					<van-checkbox v-model="isCooperate" shape="square">愿意配合查处</van-checkbox>
				</div>
			</div>
			<div class="middle-text">
				<textarea placeholder="请输入内容" v-model="content"></textarea>
				<div class="uploader">
					<div class="uploader-item" v-for="(vo, index) in picture">
						<img class="uploader-close" src="@/assets/img/uploader_icon_close.png" @click="deletePicture(index)" />
						<img class="uploader-image" :src="vo.url" />
					</div>
					<div class="uploader-item uploader-item-select"><van-uploader :after-read="onSelect" v-show="isShowUploader" upload-icon="plus"></van-uploader></div>
				</div>
			</div>
			<div class="create-footer"><div class="footer-button" @click="onSubmit">提交任务</div></div>
			<van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
				<van-datetime-picker v-model="currentDate" type="datetime" title="选择案发时间" :min-date="minDate" :max-date="maxDate" @confirm="confirm" @cancel="show = false" />
			</van-popup>
		</div>
	</div>
</template>

<script>
import UploadApi from '@/api/upload';
import VantVendor from '@/vendor/vant';
import Moment from '@/vendor/moment';
import Event from '@/store/event';
import ClueReport from '@/api/clueReport';
import ValidatorVendor from '@/vendor/validator';
export default {
	name: 'ClueReport',
	data() {
		return {
			picture: [],
			content: '',
			show: false,
			isShowUploader: true,
			minDate: new Date(2015, 0, 1),
			maxDate: new Date(2030, 12, 31),
			currentDate: new Date(),
			time:'',
			timeText:'请选择案发时间',
			typeId:"",
			typeText:"请选择反映类型",
			policeId:"",
			policeText:"请选择公安局",
			isSecrecy: true,
			isCooperate: true,
			latitude: 28.420919,
			longitude: 117.956085,
			address: "",
			detail_address: '',
			validator: null,
		};
	},
	watch: {
		picture(newVal, oldVal) {
			this.isShowUploader = newVal.length < 2;
		}
	},
	created() {
		this.$store.commit('keep/setKeep', 'ClueReport');
		Event.$off(['onClueType','onCluePolice','onClueMap']);
		
		Event.$on('onClueType', item => {
			console.log(item)
			this.typeText = item.name
			this.typeId = item.type_id
		});
		Event.$on('onCluePolice', item => {
			console.log(item)
			this.policeText = item.name
			this.policeId = item.org_id
		});
		Event.$on('onClueMap', item => {
			console.log(item)
			this.latitude = item.latitude
			this.longitude = item.longitude
			this.address = item.address
		});
		this.onValidator()
	},
	methods: {
		confirm(value){
			this.timeText = Moment(value).format('YYYY-MM-DD HH:mm');
			this.time = Moment(value).unix() 
			this.show = false
		},
		onSelect(selected) {
			VantVendor.Toast.loading('上传中');
			UploadApi.image(selected.file)
				.then(result => {
					this.picture.push({
						url: result.data.fileUrl,
						path: result.data.filePath
					});
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				})
				.finally(() => {
					VantVendor.Toast.clear();
				});
		},
		deletePicture(index) {
			this.picture.splice(index, 1);
		},
		onValidator(){
			this.validator = new ValidatorVendor({
				rules: {
					type_id: {
						required: true,
					},
					org_id: {
						required: true
					},
					occur_time: {
						required: true,
					},
					address: {
						required: true
					},
					detail_address: {
						required: true,
					},
					content: {
						required: true
					},
					photos: {
						required: true
					}
				},
				messages: {
					type_id: {
						required: '反映类型不能为空'
					},
					org_id: {
						required: '公安局不能为空'
					},
					occur_time: {
						required: '案发时间不能为空'
					},
					address: {
						required: '案发位置不能为空'
					},
					detail_address: {
						required: '详细地址不能为空'
					},
					content: {
						required: '内容不能为空'
					},
					photos: {
						required: '图片不能为空'
					}
				}
			});
		},
		onSubmit() {
			let params = {
				type_id: this.typeId,
				org_id: this.policeId,
				occur_time: this.time,
				
				latitude: this.latitude,
				longitude: this.longitude,
				address: this.address,
				
				detail_address: this.detail_address,
				is_secrecy: this.isSecrecy ? 1 : 2,
				is_cooperate: this.isCooperate ? 1 : 2,
				content: this.content,
				photos: this.picture.map(item => item.path).join()
			};
			if (!this.validator.checkData(params)) {
				return;
			}
			ClueReport.clueReport(params)
				.then(result=>{
					VantVendor.Toast.success("提交成功");
					this.$router.push({
						name: 'ClueReportList'
					});
				},error=>{
					VantVendor.Toast(error.msg);
				})
		},
	}
};
</script>

<style scoped lang="less">
.ClueReport {
	background: #F5F6F8;
	min-height: 100vh;
	position: relative;
	padding-bottom: 55px;
	.container{
		padding: 10px;
		box-sizing: border-box;
		.header-top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 8px;
			.header-top-title{
				font-weight: bold;
				font-size: 17px;
				line-height: 20px;
				color: #1C1D1D;
			}
			.header-top-info{
				font-size: 14px;
				line-height: 20px;
				color: #1C1D1D;
			}
		}
		.head-form {
			.head-form-item {
				background: #fff;
				border-radius: 6px;
				padding: 13px 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;
				.item-left {
					font-size: 15px;
					line-height: 22px;
					color: #44484e;
				}
				.item-right {
					font-size: 15px;
					color: #1c1d1d;
					display: flex;
					align-items: center;
					textarea{
						min-width: 240px;
						border: 0;
						height: 18px;
						text-align: right;
					}
					
					.item-right-text {
						font-size: 15px;
						color: #1c1d1d;
						max-width: 180px;
						flex-wrap: wrap;
					}
					.item-right-box {
						font-size: 15px;
						color: #3377FF;
						margin-left: 5px;
					}
					.item-right-box-picture{
						width: 20px;
						height: 20px;
						margin-left: 4px;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}
		
		.head-top {
			padding: 10px;
			padding-bottom: 0;
			background-color: #fff;
			border-radius: 6px;
			.head-middle {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 12px;
				.head-top-left {
					.head-top-text {
						font-size: 14px;
						line-height: 28px;
						color: #666;
					}
					.head-top-title {
						font-size: 18px;
						line-height: 26px;
						color: #000;
					}
				}
				.head-top-right {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					.right-text {
						font-size: 14px;
						line-height: 26px;
						color: #666;
					}
				}
			}
		
			.head-top-bottom {
				margin-top: -20px;
				height: 13px;
				.head-top-bottom-picture {
					width: 100%;
					height: 2px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
		
		.head-empty-address {
			width: 100%;
			height: 60px;
			line-height: 60px;
			background-color: #fff;
			border-radius: 6px;
			display: flex;
			justify-content: center;
			align-items: center;
		
			.head-empty-address-text {
				margin-left: 5px;
				font-size: 16px;
				color: #666;
			}
		
			.van-icon {
				font-size: 18px;
				color: #666;
			}
		}
		.item-footer{
			margin-top: 8px;
			display: flex;
			align-items: center;
			.footer-select{
				margin-right: 16px;
				font-size: 14px;
				line-height: 20px;
				color: #777B81;
				/deep/.van-icon{
					border-radius: 2px;
					color: #fff;
				}
			}
		}
		.middle-text {
			width: 100%;
			height: 100%;
			padding: 10px;
			background-color: #fff;
			box-sizing: border-box;
			border-radius: 6px;
			margin-top: 10px;
			position: relative;
		
			textarea {
				width: 100%;
				height: 124px;
				background-color: #fff;
				border: 0;
				padding: 0;
				font-size: 16px;
			}
		
			.uploader {
				display: flex;
				margin-top: 36px;
		
				.uploader-item {
					position: relative;
					margin-right: 12px;
		
					&:last-child {
						margin-right: 0;
					}
		
					.uploader-close {
						position: absolute;
						top: 5px;
						right: 5px;
						width: 16px;
						height: 16px;
					}
		
					.uploader-image {
						width: 75px;
						height: 75px;
						object-fit: cover;
						border-radius: 6px;
					}
				}
		
				.uploader-item-select {
					position: relative;
		
					/deep/ .van-uploader__upload {
						width: 75px;
						height: 75px;
						margin: 0;
						border-radius: 6px;
						background-color: #f2f2f2;
		
						.van-icon {
							font-size: 30px;
							color: #aaa;
						}
					}
				}
			}
		}
		
		.create-footer {
			position: fixed;
			bottom: 12px;
			left: 0;
			padding: 0 8px;
			width: 100%;
			box-sizing: border-box;
			z-index: 10;
			.footer-button {
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				padding: 11px;
				color: #fff;
				background: linear-gradient(92.48deg, #3377FF 0%, #3377FF 100%);
				box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
				border-radius: 4px;
			}
		}
	}

}
</style>
